<template>
  <getecma-single-content-layout>
    <template #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mt--xl " />
      <div class="d--flex justify-content--space-between align-items--center">
        <getecma-header class="mt--md">Recorrências</getecma-header>
        <div class="d--flex align-items--center" @keyup.enter="onSearchInput">
          <el-input
            v-model="searchQuery"
            placeholder="Buscar recorrência"
            class="input-with-select"
            clearable
            @clear="onClearSearch">
            <el-button slot="append" icon="el-icon-search" @click="onSearchInput"></el-button>
          </el-input>
          <getecma-button v-if="isAdmin" style="margin-left: 5%;" @click="onCreateRecurrence">
            Adicionar Recorrência
          </getecma-button>
        </div>
      </div>
      <div class="d-flex scroll mb--lg justify-content-center" :style="isMinLayout() ? 'height: 50px;':''" @scroll="infinityScroll($event, recurrences)">
        <div v-if="recurrences.data.length" class="w-100">
          <div
            v-for="recurrence in recurrences.data"
            :key="recurrence.id"
            class="w-100 mb--md">
            <getecma-recurrence-card
              :recurrence="recurrence" />
          </div>
        </div>
        <getecma-empty-box v-else description="Nenhuma recorrência encontrada" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { goToCreateRecurrence } from '@/modules/recurrence/recurrence.routes';
import { fetchRecurrences } from '@/modules/recurrence/recurrence.service';
import { getters } from '@/modules/user/user.store';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { toastError } from '@/services/toastService';
import { infinityScroll } from '@/helpers/scroll/infinity';
import { isMinLayout } from '@/helpers/layout/layout';

import GetecmaRecurrenceCard from '@/modules/recurrence/components/RecurrenceCard.vue';
import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';

export default {
  name: 'GetecmaMySpaceRecurrences',
  components: {
    GetecmaRecurrenceCard,
    GetecmaSingleContentLayout,
    GetecmaBreadcrumb,
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Recorrências', path: this.$route.path },
      ],
      recurrences: { data: [], pagination: { page: 1, limit: 20, lastPage: 0 } },
      searchQuery: '',
    };
  },
  computed: {
    isAdmin() {
      return getters.getUser().permission === USERS_ROLES.ADMIN;
    },
  },
  mounted() {
    this.fetchRecurrences();
  },
  methods: {
    isMinLayout,
    fetchRecurrences() {
      this.recurrences.loading = true;
      fetchRecurrences({ limit: this.recurrences.pagination.limit, page: this.recurrences.pagination.page, search: this.searchQuery })
        .then(({ recurrences, total_recurrences: total }) => {
          if (this.searchQuery === '') {
            this.recurrences.data.push(...recurrences);
          } else {
            this.recurrences.data = recurrences;
          }
          Object.assign(this.recurrences.pagination, { total: parseInt(total), lastPage: Math.ceil(total / this.recurrences.pagination.limit) });
        })
        .catch(() => toastError('Erro ao obter recorrências.'))
        .finally(() => { this.recurrences.loading = false; });
    },
    onCreateRecurrence() {
      goToCreateRecurrence(this.$router);
    },
    infinityScroll(event, content) {
      infinityScroll(event, content)
        .then(() => {
          this.fetchRecurrences();
        })
        .catch(() => {});
    },
    onSearchInput() {
      this.recurrences.pagination.page = 1;
      this.recurrences.pagination.lastPage = 0;
      this.recurrences.data = [];
      this.fetchRecurrences();
    },
    onClearSearch() {
      this.searchQuery = '';
      this.onSearchInput();
    },
  },
};
</script>
